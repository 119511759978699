<template>
  <div class="login-page">
    <!-- <div class="logo" @click="toindex()">
      <img src="@/assets/logo.png" />
    </div> -->
    <!-- <hr />
    <div class="body">
      <div class="content">登录中……</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "loading",
  // inject:['app'],
  data() {
    return {
      phone: "",
      istrue: 0,
      msg: "",
      activeName: "first",
    };
  },
  mounted() {
    this.login();
  },
  methods: {
    toindex() {
      this.$router.push("./Login");
    },
    login() {
      var that = this;
      // const loading = this.$loading({
      //   lock: true,
      //   text: "登录中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      $.ajax({
        async:false,
        type: "get",
        url: this.$store.state.user.q_url + "/api.v2/login/multipoint",
        //   headers: {'Authorization': "Bearer ML-" + that.$route.query.token},
        beforeSend: function (request) {
          request.setRequestHeader(
            "Authorization",
            "Bearer ML-" + that.$route.query.token
          );
        },
        success: function (res) {
          if (res.success) {
            that.$store.commit("user/phone", res.data.phone);
            that.$store.commit("user/p_id", res.data.p_id);
            that.$store.commit("user/m_id", res.data.m_id);
            that.$store.commit("user/real_name", res.data.Real_name);
            that.$store.commit("user/u_type", res.data.virtual);
            that.$store.commit("user/reg_time", res.data.createtime);
            that.$store.commit("user/lsxd_open", res.data.lsxd_goods_open);
            var user = that.user;
            that.$store.commit("user/user_info", user);
            that.$store.commit("login/login_info", "true");
            if (res.data.store_name != undefined) {
              that.$store.commit("user/storename", res.data.store_name);
            }
            that.$store.commit("user/stock_remind", res.data.stock_remind);
            // loading.close();
            that.$router.push("./my_manage");
          } else {
            // that.$message({
            //   message: "自动登录失败！",
            //   type: "error",
            //   center: "true",
            //   offset: "60",
            // });
            that.$router.push("./Login");
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-page {
  position: relative;
  padding: 0px;
  height: 800px;
  width: 100%;
}
.body {
  width: 100%;
  height: 700px;
  position: relative;
}
.content {
  width: 300px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 24px;
}
.lab {
  font-size: 16px;
  margin: 10px 10px 10px 20px;
  display: inline-block;
}
.goods_search {
  width: 260px;
  height: 30px;
  border-color: initial;
  margin-left: 3px;
}
.cell {
  margin: 20px auto;
  text-align: center;
}
.login-page /deep/ .el-tabs {
  width: 850px;
  margin: 50px auto;
}
// .login-page /deep/ .el-tabs__header {width: 830px;}
.login-page /deep/ .el-tabs__item {
  width: 420px;
  font-size: 16px;
  text-align: center;
}
.login-page /deep/ .el-tabs__content {
  min-height: 600px;
  color: #000;
}
.logo {
  width: 60px;
  height: 60px;
  margin: 15px 0 15px 360px;

  > img {
    width: 100%;
    height: 100%;
  }
}
</style>